'use client'

import React, { ReactNode } from 'react'
import {
    Box,
    Container,
    Stack,
    SimpleGrid,
    Text,
    VisuallyHidden,
    chakra,
    useColorModeValue,
    Flex,
    Image,
    ListItem,
    List,
} from '@chakra-ui/react'
import { FaYoutube, FaInstagram, FaFacebook } from 'react-icons/fa'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'bold'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    )
}

const Logo = () => {
    return (
        <Link to='/'>
            <Image src='/logo_orbitor_horizontal_green+white.webp' style={{ width: '180px' }} />
        </Link>
    )
}

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode
    label: string
    href: string
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    )
}

export default function Footer() {
    return (
        <Flex justifyContent={'center'} alignItems={'center'}
            bg={useColorModeValue('gray.800', 'gray.900')}
            flexDir={'column'}>
            <Box
                w={"100%"}
                maxW={'1440px'}
                color={('gray.50')}>
                <Container as={Stack} py={10} maxW={'100%'}>

                    <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8} templateColumns={{ md: '0.6fr 1fr 1fr' }}>
                        <Stack spacing={6} mb={0}>
                            <Box>
                                <Logo />
                            </Box>
                            <Text fontWeight={'bold'} fontSize={'sm'}>공간의 이야기를 플레이(Play)하다 </Text>
                        </Stack>

                        {/* 2열 */}
                        <Stack align={'flex-start'}>
                            <List>
                                <ListHeader>사업자 정보</ListHeader>
                                <ListItem>
                                    <Text as={'span'} fontWeight={'bold'}>
                                        오르비터 | 등록번호 :
                                    </Text>{' '}
                                    791-14-01475{' '}
                                    <Text as={'span'} fontWeight={'bold'}>
                                        | 대표 :
                                    </Text>{' '}
                                    문영섭
                                </ListItem>
                                <ListItem>
                                    {/* <Text as={'span'} fontWeight={'bold'}>
                                        Bracelet:
                                    </Text>{' '} */}
                                    인천광역시 연수구 송도과학로 70, 업무동 3215호
                                </ListItem>
                                <ListItem>
                                    (송도동, 송도AT센터)
                                </ListItem>
                                <ListItem>
                                    <Text as={'span'} fontWeight={'bold'}>
                                        E-mail |
                                    </Text>{' '}
                                    <chakra.a href="mailto:myslover@naver.com" color="blue.500">
                                        myslover@naver.com
                                    </chakra.a>
                                </ListItem>
                                <ListItem >
                                    <Text as={'span'} fontWeight={'bold'}>
                                        Phone |
                                    </Text>{' '}
                                    <chakra.a href='010-9887-5006' color='blue.500'>
                                        010-9887-5006
                                    </chakra.a>
                                </ListItem>
                            </List>
                        </Stack>

                        {/* 3열 부분 */}
                        <Stack align={'flex-end'}>
                            <ListHeader>Menu</ListHeader>
                            <Box as="a" href={'/Xr/Ar'}>
                                Ar
                            </Box>
                            <Box as="a" href={'/Xr/Vr'}>
                                Vr
                            </Box>
                            <Box as="a" href={'/Company'}>
                                Company
                            </Box>
                            <Box as="a" href={'/Services'}>
                                Services
                            </Box>
                        </Stack>

                    </SimpleGrid>
                </Container>

                {/* 2행 부분 */}
                <Box
                    borderTopWidth={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}>
                    <Container
                        as={Stack}
                        maxW={'1440px'}
                        py={4}
                        direction={{ base: 'column', md: 'row' }}
                        spacing={4}
                        justify={{ md: 'space-between' }}
                        align={{ md: 'center' }}>
                        <Text>Copyright © 2021 Orbitor. All rights reserved</Text>
                        <Stack direction={'row'} spacing={6}>
                            <SocialButton label={'Facebook'} href={'https://www.facebook.com/sosok.official'}>
                                <FaFacebook />
                            </SocialButton>
                            <SocialButton label={'YouTube'} href={'https://www.youtube.com/@sosok2676'}>
                                <FaYoutube />
                            </SocialButton>
                            <SocialButton label={'Instagram'} href={'https://www.instagram.com/sosok__official/'}>
                                <FaInstagram />
                            </SocialButton>
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </Flex>
    )
}